<template>
  <div id='page-not-found-screen'>
    <div class="container hungarospa-container">
      <div class="content-section">
        <icon-danger />

        <h1 class="title">{{ $t('pages.pageNotFound.title') }}</h1>
        <span class="description">{{ $t('pages.pageNotFound.description') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import IconDanger from '@/components/shared/elements/icons/IconDanger';

export default {
  name: 'PageNotFound',
  props: {},
  components: {
    IconDanger,
  },
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
$contentWidth: 600px;

#page-not-found-screen {
  padding: 85px 0 370px;

  @media screen and (max-width: $breakpointDownXs) {
    padding-bottom: 120px;
  }
}

.icon-danger {
  margin: 0 auto 30px;
  text-align: center;

  &::v-deep svg {
    max-height: 140px;

    @media screen and (max-width: $breakpointDownXs) {
      max-height: 100px;
    }
  }
}

.content-section {
  width: $contentWidth;
  border-radius: $globalBorderRadius;
  box-shadow: $lightDropdownShadow;
  background-color: $white;
  margin: 0 auto;
  padding: 45px 80px 50px;

  @media screen and (max-width: $breakpointDownSm) {
    width: 100%;
    padding-left: 45px;
    padding-right: 45px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    padding: 35px;
  }
}

.title,
.description {
  display: block;
  text-align: center;
}

.title {
  margin: 0;
  font-size: 25px;
  font-weight: 700;
  color: $paymentFailedColor;
  line-height: 1.2;
  margin: 0 0 20px;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 20px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    font-size: 18px;
  }
}

.description {
  font-weight: 500;
  font-size: 15px;
  margin: 0 0 30px;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 13px;
  }
}
</style>
